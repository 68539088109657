<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-3@m uk-width-1-5@l uk-width-1-5@xl">
      <div id="toggle-animation">
        <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
          <ul
            v-if="isShow"
            id="sidebar"
            class="uk-nav-default uk-nav-parent-icon sidebar-menu"
            uk-nav
          >
            <li
              v-for="(items, i) in filterSidebarActivity"
              :key="items.name"
              :class="[showSidebarClass(items, items.childDisplay), {'active-li': items.name === showAct}, {'list-menu': true}]"
            >
              <a
                :href="items.to"
                class="sidebar-display"
                :class="{'active-child': items.name === showAct}"
                @click="showActionContainer(items, i)"
              >
                <span class="icon-dropdown-pemeliharaan uk-margin-small-right">
                  {{ items.name }}
                </span>
              </a>
              <div
                v-if="items.name === 'PANEN' && screenWidth"
                class="uk-padding-remove"
                uk-dropdown
              >
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav uk-dropdown-nav uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <ul
                  v-if="items.childDisplay && items.name === 'PANEN'"
                  class="uk-nav-sub"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </template>

              <div
                v-if="items.name === 'PEMELIHARAAN' && screenWidth"
                class="uk-padding-remove"
                uk-dropdown
              >
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav uk-dropdown-nav uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <ul
                  v-if="items.childDisplay && items.name === 'PEMELIHARAAN'"
                  class="uk-nav-sub"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
          
          <ul
            v-else
            class="uk-nav-default uk-nav-parent-icon"
            uk-nav
          >
            <li
              v-for="items in filterSidebarActivity"
              :key="items.name"
              :class="getSidebarClass(items.to)"
            >
              <router-link :to="items.to">
                <span :uk-icon="items.icon" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-2-3@m uk-width-4-5@l uk-width-4-5@xl left-repeat">
      <div
        id="kandang-card-activity"
        class="uk-card uk-card-default kandang-card"
      >
        <div uk-margin>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tahapan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.name"
                    class="uk-input"
                    type="text"
                    name="name"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tanggal Pelaksanaan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-form-controls">
                    <input
                      v-model="dataDetailActivity.work_date_start"
                      class="uk-input"
                      type="text"
                      name="work_date_start"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Total Kegiatan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.total_activity"
                    class="uk-input"
                    type="text"
                    name="total_activity"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Populasi (ekor)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.population"
                    class="uk-input"
                    type="text"
                    name="population"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Deplesi (ekor)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.dead_chicken"
                    class="uk-input"
                    type="text"
                    name="dead_chicken"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Bobot Sampel (gr)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.weight_sample"
                    class="uk-input"
                    type="text"
                    name="weight_sample"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Stok Pakan (sak)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.food_stock"
                    class="uk-input"
                    type="text"
                    name="food_stock"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-card uk-card-default kandang-card">
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div v-if="row.flag === showAct">
            <!-- <div> -->
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-2 uk-margin-auto-vertical uk-text-left">
                <h6 class="uk-text-bold">
                  Kegiatan
                </h6>
              </div>
              <div class="uk-width-1-2 uk-text-right">
                <button
                  class="uk-button uk-button-default-danger"
                  type="button"
                  @click="removeActivities(row, i)"
                >
                  <img
                    v-lazy="`${images}/icon/trash.svg`"
                    alt=""
                    class="uk-margin-small-right"
                  >
                  Hapus
                </button>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <multiselect 
                      :id="`name_activity[${i}]`" 
                      v-model="row.activity_id"
                      label="name" 
                      track-by="name" 
                      placeholder="Pilih" 
                      open-direction="bottom" 
                      :options="dataDetailActivityCategorys" 
                      :searchable="true" 
                      :loading="isLoading"
                      :close-on-select="true" 
                      :max-height="500" 
                      :show-no-results="true" 
                      @remove="clearDetailActivity(i)"
                      @select="selectDetailActivity($event, i)"
                    >
                      <span slot="noResult">Oops! Data tidak ditemukan.</span>
                    </multiselect>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <div class="uk-form-controls">
                      <input
                        v-model="row.activity_category"
                        class="uk-input"
                        type="text"
                        name="activity_category"
                        disabled
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Sub-Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.sub_activity_category"
                      class="uk-input"
                      type="text"
                      name="sub_activity_category"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Keterangan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <textarea
                      id=""
                      v-model="row.description"
                      name="description"
                      class="uk-textarea"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Tipe Input
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.input_type"
                      class="uk-input"
                      type="text"
                      name="input_type"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Waktu Pengerjaan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      class="uk-input"
                      :value="row.time_start +' - '+ row.time_end"
                      type="text"
                      name="processing_time"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr v-show="formActivities.length > 1">
          </div>
        </div>
        <h2
          class="add-activity-line"
          @click="addActivities(showAct)"
        >
          <span>
            <img
              v-lazy="`${images}/icon/plus-activity.svg`"
              alt=""
              class="uk-margin-small-left"
            >
            Tambah Kegiatan
          </span>
        </h2>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-right">
            <button
              class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
              type="button"
              @click="goToBack"
            >
              Batal
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="showSaveConfirmModal"
            >
              <img
                v-lazy="`${images}/icon/save.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { notificationDanger } from '@/utils/notification'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    dataDeleteMessage: {
      required: false,
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    panenDate: Number,
    // eslint-disable-next-line vue/require-default-prop
    chickOutDate: Number
  },
  data() {
    return {
      isLoading: false,
      isShow: true,
      showAct: '',
      images: PREFIX_IMAGE,
      showProduction: process.env.VUE_APP_ENV == 'production',
      metaFilterActivityLog: {
        log_cycle: ''
      },
      metaFilterActivityLogDetail: {
        log_activity_id : ''
      },
      formActivities: [],
      // activities: [{ id: 1, code: 'Cek Suhu'}],
      daysTo: 1,
      showContActitvity: false,
      filterSidebarActivity: [],
      tempActivities : [],
      detailActivityChoose: '',
      dataActivityLogs: [],
      dataDetailActivity: {},
      showContainerLog: 0,
      idDataDetailActivity : '',
      setIndexActivity: null,
      dataDetailActivityCategorys: [],
      pemeliharaanLength: null,
      panenLength: null,
      index_detail_activity: false,
      temporary_add: false,
      remove_detail_activity: false,
      screenWidth: false
    }
  },

  computed: {
    ...mapGetters({
      detailActivityCategorys: 'detailActivityCategory/detailActivityCategorys',
      activityLogs: 'activityLog/activityLogs',
      activityLogWithDetails: 'activityLog/activityLogWithDetails',
      metaActivityLog: 'activityLog/meta',
      metaActivityLogDetail: 'activityLogDetail/meta',
      metaActivity: 'activity/meta',
      metaDetailActivityCategory: 'detailActivityCategory/meta'
    })
  },
  watch: {
    tempActivities () {
      this.dataDetailActivity.work_date_start = this.tempActivities != [] ? this.tempActivities.find(i => i.id === this.dataDetailActivity.id).work_date_start : this.dataDetailActivity.work_date_start
    },
    async dataDeleteMessage () {
      if (this.dataDeleteMessage.message === 'OK')
        this.removeActivitiesWhenSuccess()
    },
    async formActivities() {
      this.pemeliharaanLength = this.activityLogWithDetails.filter(x => x.name.includes('HARI KE') && x.name.indexOf("PANEN")).length
      this.panenLength = this.activityLogWithDetails.filter(x => x.name.includes('PANEN')).length
      await this.setDetailLog()
    },
    async panenDate() {
      this.pemeliharaanLength = this.activityLogWithDetails.filter(x => x.name.includes('HARI KE') && x.name.indexOf("PANEN")).length
      this.panenLength = this.activityLogWithDetails.filter(x => x.name.includes('PANEN')).length
      await this.maintenanceDays(this.panenDate, this.chickOutDate)
      await this.setDetailLog()
    },
    async chickOutDate() {
      this.pemeliharaanLength = this.activityLogWithDetails.filter(x => x.name.includes('HARI KE') && x.name.indexOf("PANEN")).length
      this.panenLength = this.activityLogWithDetails.filter(x => x.name.includes('PANEN')).length
      await this.maintenanceDays(this.panenDate, this.chickOutDate)
      await this.setDetailLog()
    },
    async metaActivityLogDetail() {
      await this.getActivityLogDetail(this.metaActivityLogDetail)
    },
    async metaDetailActivityCategory() {
      await this.getDetailActivityCategory(this.metaDetailActivityCategory)
      this.dataDetailActivityCategorys = this.detailActivityCategorys ? this.detailActivityCategorys.data ? this.detailActivityCategorys.data : [] : []
    }
  },
  async mounted() {
    await this.getDataActivityLogWithDetail()
    await this.getDetailActivityCategory(this.metaDetailActivityCategory)
    this.screenWidth = screen.width <= 768
    this.dataDetailActivityCategorys = this.detailActivityCategorys ? this.detailActivityCategorys.data ? this.detailActivityCategorys.data : [] : []
  },
  methods: {
    async setDetailLog (data) {
      await this.filterData(this.panenDate)
      this.$emit('dataDetailLog', this.formActivities)
      this.$emit('dataActivityLogWithDetails', this.tempActivities)
      this.$emit('dataPemeliharaanLength', this.pemeliharaanLength)
      this.$emit('dataPanenLength', this.panenLength)
      this.$emit('buttonSaveClick', data)
    },
    ...mapActions({
      getActivity: 'activity/getActivity',
      getDetailActivityCategory: 'detailActivityCategory/getDetailActivityCategory',
      getActivityLog: 'activityLog/getActivityLog',
      getActivityLogDetail: 'activityLogDetail/getActivityLogDetail',
      getActivityLogWithDetail: 'activityLog/getActivityLogWithDetail'
    }),
    ...mapMutations({
      setMetaActivity: 'activity/SET_META',
      setMetaActivityLog: 'activityLog/SET_META',
      setMetaActivityLogDetail: 'activityLogDetail/SET_META',
      setMetaDetailActivityCategory: 'detailActivityCategory/SET_META',
      setModalDelete: 'activityLogDetail/SET_MODAL_DELETE'
    }),
    filterData(childLength) {
      this.tempActivities = []
      const dataActivity = this.activityLogWithDetails ? this.activityLogWithDetails : []
      let countData = 0
      dataActivity.forEach((el) => {
        if (el.name.search(`HARI KE`) == 0) {
          countData++
          if (childLength.toString() == 'NaN' || childLength === 0) {
            this.tempActivities.push(el)
          } else if (childLength !== 0 && countData <= childLength) {
            this.tempActivities.push(el)
          }
        } else {
          this.tempActivities.push(el)
        }
      })
    },
    async changeLimitActivity() {
      this.setMetaActivity({
        ...this.metaActivity,
        limit: 100
      })
    },
    async changeLimitActivityLog() {
      this.setMetaActivityLog({
        ...this.metaFilterActivityLogDetail,
        limit: 100
      })
    },
    async pushFormActivities(items) {
      // COLLECT DETAIL ACTIVITIES
      const data = items.log_detail_activity ? items.log_detail_activity : []

      data.forEach(el => {
        const act = {
          activity_category: '',
          activity_category_id: '',
          detail_activity_category_id: '',
          created_at: '',
          description: '',
          id: el.id,
          input_type: '',
          input_type_id: '',
          name: el.name,
          sub_activity_category: '',
          sub_activity_category_id: '',
          time_end: '',
          time_start: '',
          updated_at: '',
          updated_by: ''
        }
        const pushMaster = {
          flag: el.log_activity,
          activity: el.activity,
          activity_category: el.activity_category,
          activity_category_id: el.activity_category_id,
          detail_activity_category_id: el.activity_category_id,
          activity_id: act,
          description: el.description,
          id: el.id,
          input_type: el.input_type,
          input_type_id: el.input_type_id,
          name: el.name,
          sub_activity_category: el.sub_activity_category,
          sub_activity_category_id: el.sub_activity_category_id,
          time_start: el.time_start,
          time_end: el.time_end
        }
        this.formActivities.push(pushMaster)
      })
    },
    async getDataActivityLog() {
      await this.getActivityLog(this.$route.params.id)
      this.is_loading = false
      this.dataActivityLogs = this.activityLogs ? this.activityLogs : []

      await this.maintenanceDays(this.panenDate, this.chickOutDate)
      const getItemsFirst = this.activityLogs ? this.activityLogs[0] : []
      this.idDataDetailActivity = getItemsFirst.id
      const assignOnLoad = {
        created_at: getItemsFirst.created_at,
        dead_chicken: getItemsFirst.dead_chicken,
        expire: getItemsFirst.expire,
        food_stock: getItemsFirst.food_stock,
        id: getItemsFirst.id,
        log_cycle_id: getItemsFirst.log_cycle_id,
        name: getItemsFirst.name,
        note: getItemsFirst.note,
        population: getItemsFirst.population,
        sequence: getItemsFirst.sequence,
        updated_at: getItemsFirst.updated_at,
        updated_by: getItemsFirst.updated_by,
        weight_sample: getItemsFirst.weight_sample,
        showProduction: true,
        to: '#',
        icon: 'icon: triangle-right',
        role: ['super_admin'],
        displayMaintenance: true,
        childDisplay: false,
        children    : []
      }
      await this.showActionContainer(assignOnLoad, 0)
    },
    async getDataActivityLogWithDetail() {
      await this.getActivityLogWithDetail(this.$route.params.id)
      this.is_loading = false
      this.dataActivityLogs = this.activityLogWithDetails ? this.activityLogWithDetails : []

      await this.maintenanceDays(this.panenDate, this.chickOutDate)
      const getItems = this.activityLogWithDetails ? this.activityLogWithDetails : []
      // FOR TEMP ACTIVITIES DETAIL
      getItems.forEach((el, i) => {
        if (i === 0) {
          this.showActionContainer(el, i)
          this.pushFormActivities(el)
        } else {
          this.pushFormActivities(el)
        }
      })
      // this.idDataDetailActivity = getItemsFirst.id
      // const assignOnLoad = {
      //   work_date_start: getItemsFirst.work_date_start,
      //   work_date_end: getItemsFirst.work_date_end,
      //   log_detail_activity: getItemsFirst.log_detail_activity,
      //   created_at: getItemsFirst.created_at,
      //   dead_chicken: getItemsFirst.dead_chicken,
      //   expire: getItemsFirst.expire,
      //   food_stock: getItemsFirst.food_stock,
      //   id: getItemsFirst.id,
      //   log_cycle_id: getItemsFirst.log_cycle_id,
      //   name: getItemsFirst.name,
      //   note: getItemsFirst.note,
      //   population: getItemsFirst.population,
      //   sequence: getItemsFirst.sequence,
      //   updated_at: getItemsFirst.updated_at,
      //   updated_by: getItemsFirst.updated_by,
      //   weight_sample: getItemsFirst.weight_sample,
      //   showProduction: true,
      //   to: '#',
      //   icon: 'icon: triangle-right',
      //   role: ['super_admin'],
      //   displayMaintenance: true,
      //   childDisplay: false,
      //   children    : []
      // }
      // await this.showActionContainer(assignOnLoad, 0)
    },
    async maintenanceDays(childLength, panenChildLength) {
      const dataActivity = this.dataActivityLogs ? this.dataActivityLogs : []
      this.filterSidebarActivity = []
      const menuChilds = []
      const menuPanenChilds = []
      let child = {}
      let panenChild = {}
      let menu = {}
      dataActivity.forEach(el => {
        if (el.name.search('HARI KE') == 0) {
          child = {
            work_date_start: el.work_date_start,
            work_date_end: el.work_date_end,
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            menu_include: 'Pemeliharaan',
            children: []
          }
          menuChilds.push(child)
        } else if (el.name.search('PANEN HARI KE') == 0) {
          panenChild = {
            work_date_start: el.work_date_start,
            work_date_end: el.work_date_end,
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            menu_include: 'Panen',
            children    : []
          }
          menuPanenChilds.push(panenChild)
        } else {
          menu = {
            work_date_start: el.work_date_start,
            work_date_end: el.work_date_end,
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: false,
            childDisplay: false,
            children    : []
          }
          this.filterSidebarActivity.push(menu)
        }
      })
      // ASSIGN DAYS PEMELIHARAAN
      let maintenanceMenu = {}
      let harvestMenu = {}
      if (dataActivity.length > 0) {
        maintenanceMenu = {
          name: 'PEMELIHARAAN',
          showProduction: true,
          to: '#',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : childLength ? (childLength === 0 ? menuChilds : menuChilds.slice(0, Math.round(childLength))) : menuChilds
        }
        this.filterSidebarActivity.splice(2, 0, maintenanceMenu)
      }
      if (dataActivity.length > 0) {
        harvestMenu = {
          name: 'PANEN',
          showProduction: true,
          to: '#',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : panenChildLength ? (panenChildLength === 0 ? menuPanenChilds : menuPanenChilds.slice(0, Math.round(panenChildLength))) : menuPanenChilds
        }
        this.filterSidebarActivity.splice(3, 0, harvestMenu)
      }
    },
    async showActionContainer(items, i) {
      // await this.pushFormActivities(items)
      this.idDataDetailActivity = items.id ? items.id : ''
      this.daysTo = null
      if (!items.childDisplay) {
        this.showAct = items.name
        // this.showAct = true
        this.showContActitvity = false
        this.showContainerLog = i
      } else {
        this.showAct = ''
      }

      this.dataDetailActivity = {
        created_at: items.created_at,
        dead_chicken: items.dead_chicken,
        expire: items.expire,
        food_stock: items.food_stock,
        id: items.id,
        log_cycle_id: items.log_cycle_id,
        name: items.name,
        note: items.note,
        population: items.population,
        sequence: items.sequence,
        updated_at: items.updated_at,
        updated_by: items.updated_by,
        weight_sample: items.weight_sample,
        work_date_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
        work_date_end: items.work_date_end,
        total_activity: items.log_detail_activity.length
      }
    },
    findActivityLogByID(id) {
      this.dataActivityLogs = []
      // FILTER FIND
      this.metaFilterActivityLog.log_cycle = id
      this.setMetaActivityLog({
        ...this.metaActivityLog,
        ...this.metaFilterActivityLog
      })
    },
    findActivityLogChangeLimit() {
      // FILTER FIND
      this.metaFilterActivityLog.limit = this.dataActivityLogs.meta.total_count
      this.setMetaActivityLog({
        ...this.metaActivityLog,
        ...this.metaFilterActivityLog
      })
    },
    async showContainerActivity(items, index) {
      // await this.pushFormActivities(items)
      if (items.menu_include === 'Panen') {
        this.daysTo = index + 1
        this.showAct = `PANEN HARI KE ${this.daysTo}`
        this.showContActitvity = true
        this.dataDetailActivity = {
          created_at: items.created_at,
          dead_chicken: items.dead_chicken,
          expire: items.expire,
          food_stock: items.food_stock,
          id: items.id,
          log_cycle_id: items.log_cycle_id,
          name: items.name,
          note: items.note,
          population: items.population,
          sequence: items.sequence,
          updated_at: items.updated_at,
          updated_by: items.updated_by,
          weight_sample: items.weight_sample,
          work_date_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
          work_date_end: items.work_date_end,
          total_activity: items.log_detail_activity.length
        }
      } else {
        this.daysTo = index + 1
        this.showAct = `HARI KE ${this.daysTo}`
        this.showContActitvity = true
        this.dataDetailActivity = {
          created_at: items.created_at,
          dead_chicken: items.dead_chicken,
          expire: items.expire,
          food_stock: items.food_stock,
          id: items.id,
          log_cycle_id: items.log_cycle_id,
          name: items.name,
          note: items.note,
          population: items.population,
          sequence: items.sequence,
          updated_at: items.updated_at,
          updated_by: items.updated_by,
          weight_sample: items.weight_sample,
          work_date_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
          work_date_end: items.work_date_end,
          total_activity: items.log_detail_activity.length
        }
      }
      // this.findActivityLogByID(child.id)
    },
    showSidebarActiveChildClass() {
      return 'active-child'
    },
    showDetailSidebar() {
      this.isShow = !this.isShow
    },
    addActivities (flag) {
      this.formActivities.push(
        {
          flag: flag,
          activity: '',
          activity_category: '',
          activity_category_id: '',
          detail_activity_category_id: '',
          activity_id: '',
          description: '',
          id: '',
          input_type: '',
          input_type_id: '',
          name: '',
          sub_activity_category: '',
          sub_activity_category_id: '',
          time_start: '',
          time_end: '',
          processing_time: ''
        }
      )
      this.temporary_add = true
    },
    async removeActivities(row, index) {
      if (row.id !== '') {
        if(this.formActivities.filter(activity => activity.flag === row.flag).length == 1){
          notificationDanger({message:`Minimal 1 Aktifitas`})
        } else {
          this.tempActivities.forEach((item) => {
            item.log_detail_activity.forEach((nestedItem) => {
              if (nestedItem.id === row.id) {
                nestedItem.id = ''
                nestedItem.activity_category_id = ''
                nestedItem.detail_activity_category_id = ''
              }
            })
          })
          this.setIndexActivity = index
          this.removeActivitiesWhenSuccess()
          this.setDetailLog()
        }
      } else {
        this.tempActivities.forEach((item) => {
          item.log_detail_activity.forEach((nestedItem) => {
            if (nestedItem.id === row.id) {
              nestedItem.id = ''
              nestedItem.activity_category_id = ''
              nestedItem.detail_activity_category_id = ''
            }
          })
        })
        this.formActivities.splice(index, 1)
        this.temporary_add = false
        this.setDetailLog()
      }
    },
    removeActivitiesWhenSuccess () {
      this.formActivities.splice(this.setIndexActivity, 1)
      this.remove_detail_activity = true
    },
    selectDetailActivity(val,i) {
      this.formActivities[i].name = val.name
      this.formActivities[i].activity_category_id = val.activity_category_id
      this.formActivities[i].detail_activity_category_id = val.activity_category_id
      this.formActivities[i].sub_activity_category_id = val.sub_activity_category_id
      this.formActivities[i].activity_id = val.activity_id
      this.formActivities[i].input_type_id = val.input_type_id
      this.formActivities[i].description = val.description
      this.formActivities[i].time_start = `${this.dataDetailActivity.work_date_start} ${val.time_start}`
      this.formActivities[i].time_end = `${this.dataDetailActivity.work_date_start} ${val.time_end}`
      this.formActivities[i].activity_category = val.activity_category
      this.formActivities[i].input_type = val.input_type
      this.formActivities[i].sub_activity_category = val.sub_activity_category
      this.formActivities[i].processing_time = `${this.formActivities[i].time_start} - ${this.formActivities[i].time_end}`
      this.setDetailLog()
    },
    clearDetailActivity (i) {
      this.formActivities[i].name = ''
      this.formActivities[i].activity_category_id = ''
      this.formActivities[i].detail_activity_category_id = ''
      this.formActivities[i].sub_activity_category_id = ''
      this.formActivities[i].activity_id = ''
      this.formActivities[i].input_type_id = ''
      this.formActivities[i].description = ''
      this.formActivities[i].time_start = ''
      this.formActivities[i].time_end = ''
      this.formActivities[i].activity_category = ''
      this.formActivities[i].input_type = ''
      this.formActivities[i].sub_activity_category = ''
      this.formActivities[i].processing_time = ''
    },
    getSidebarClass(path){
      return{
        'active': this.$route.fullPath === this.$route.fullPath+path,
        'inactive': this.$route.fullPath !== this.$route.fullPath+path
      }
    },
    showChildSidebar(path, child) {
      const classChild = path.name === 'PEMELIHARAAN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showHarvestChildSidebar(path, child) {
      const classChild = path.name === 'PANEN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showSidebarClass (path, child) {
      const active = this.getSidebarClass(path)
      const childs = this.showChildSidebar(path, child)
      const harvestChilds = this.showHarvestChildSidebar(path, child)
      const classes = `${active} ${childs} ${harvestChilds}`
      return classes
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    async showSaveConfirmModal() {
      this.temporary_add === true ? (
        this.index_detail_activity = true
      ) : (
        this.remove_detail_activity === true ? this.index_detail_activity = true : this.index_detail_activity = false
      )
      await this.setDetailLog(this.index_detail_activity)
    }
  }
}
</script>

<style scoped>
.width-large {
  width: 20.5%;
}
.width-small {
  width: 7%;
}
#toggle-animation {
  transition: width 500ms;
}
.card-sidebar {
  height: calc(100vh - 50px);
}
.detail-sidebar {
  padding-right: 10%;
  background-color: #E0EBE6;
  height: 50px;
  line-height: 50px;
}
.detail-sidebar a {
  color: #ffffff;
}
.sidebar {
  background-color: #E0EBE6;
  border-radius: 16px 0px 0px 16px;
  height: auto;
}
li {
  padding: 8px 0;
  margin: 1px 0;
}
.uk-active {
  background-color: #004428;
  border-left: 5px solid #004428;
}

.uk-active .uk-icon {
  background-color: #f1f2f3 ;
  color: #f1f2f3;
}
li.uk-active a {
  color: #cacccb;
}
.uk-nav-header {
  color: #fff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active{
  background-color: #004428;
  color: #004428;
}
.active > a{
  color: #fff !important;
}
.uk-nav-default .uk-nav-sub a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
}
.uk-card-body .uk-nav-default > li > a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
}
@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }
}
.uk-nav-default .uk-nav-sub a {
    margin-left: 30px;
}
.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 0px 0px 16px;
}
.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus, .uk-nav-default .uk-nav-sub a:hover, .uk-parent a:focus {
  color: #84deb9;
}
.left-repeat {
  padding-left: 0px;
}
#kandang-card-activity {
  height: 522px;
}
.uk-nav-sub > li > a:focus, .uk-nav-sub > li > a:hover {
  color: #84deb9;
}

.uk-card-body .uk-nav-default .uk-nav-sub {
    padding-left: 0px;
}
ul.uk-nav-sub {
    padding: 0px;
    height: auto;
    max-height: 360px;
    overflow: auto;
}
.active-child {
  color:#025231 !important;
}
.active-li {
  background-color: #FFFF;
  border-radius: 16px 0px 0px 16px;
}
.icon-dropdown-pemeliharaan {
  z-index: 100; 
  margin-right:0px !important;
}
.sidebar-display {
  display:flex; 
  flex-direction: row; 
  justify-content: space-between; 
  padding-right: 0px !important;
}

.sidebar-menu {
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  padding: 0 15px;
}

.list-menu {
  min-width: 70%;
}

.active-li {
  background-color: #FFFF;
  border-radius: 16px 16px 16px 16px;
}

.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 16px 16px 16px;
}

.sub-sidebar-menu {
  position: absolute;
  left: 0;
  background-color: #f1f3f2;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (min-width: 768px) {
  .list-menu {
    min-width: 45%;
  }
}

@media (min-width: 1024px) {
  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}

@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }

  .uk-card-body .uk-nav-default .uk-nav-sub {
      padding-left: 0px;
  }

  .left-repeat {
    padding-left: 0px;
  }

  .active-li {
    border-radius: 16px 0px 0px 16px;
  }

  .uk-maintenance {
    border-radius: 16px 0px 0px 16px;
  }

  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    border-radius: 0 0 10px 10px;
  }

}
</style>
